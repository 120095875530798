import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: D Ball Cleans & Tire Flips 5×2`}</p>
    <p>{`then,`}</p>
    <p>{`1000M SKi Erg`}</p>
    <p>{`1 Mile Run`}</p>
    <p>{`1000M Row`}</p>
    <p>{`1 Mile Assault Bike`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      